import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="/assets/logos/flipper-logo-dark.png"
          className="App-logo"
          alt="logo"
        />
        <p>Estamos em manutenção...</p>
      </header>
    </div>
  );
}

export default App;
